import { useState } from 'react';
import Image, { ImageLoaderProps, StaticImageData } from 'next/legacy/image';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { IMAGE_FOLDER_PATH } from '@/models/buildtime-constant';

export type ImageProps = {
  id?: string;
  src: string | StaticImageData;
  alt?: string;
  width?: number;
  height?: number;
  layout?: any;
  className?: string;
  priority?: boolean;
  showTitle?: boolean;
  quality?: string;
  absolute?: boolean;
  ratioChange?: boolean;
  onError?: (errorUrl: string) => void;
};

const shimmer = (w: number | string | undefined, h: number | string | undefined) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#D4D4D5" offset="20%" />
      <stop stop-color="#AAAAAB" offset="50%" />
      <stop stop-color="#D4D4D5" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#D4D4D5" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) => (typeof window === 'undefined'
  ? Buffer.from(str).toString('base64')
  : window.btoa(str));

// TODO : Remove this component
const Images = ({
  id,
  src,
  alt = '',
  layout = 'intrinsic',
  width = layout === 'fill' ? undefined : 100,
  height = layout === 'fill' ? undefined : 100,
  showTitle = false,
  className = '',
  priority = false,
  quality = '75',
  absolute = false,
  ratioChange = false,
  onError = () => {},
}: ImageProps) => {
  const [isError, setIsError] = useState<boolean>(false);
  const version = dayjs();
  const handleError = () => {
    onError(`${src}`);
    setIsError(true);
  };
  const renderImage = () => {
    if (showTitle) {
      return (
        <div style={{ position: 'relative' }}>
          <Image
            id={id}
            data-testid={id}
            alt={alt}
            src={src}
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64, ${toBase64(shimmer(width, height))}`}
            width={width}
            layout={layout}
            height={height}
            className={className}
            priority={priority}
            quality={parseInt(quality)}
            loader={({ src: srcUrl }: ImageLoaderProps) => `${srcUrl}?v=${version}`}
            onError={() => handleError()}
          />
          <p style={{
            position: 'absolute',
            marginTop: '30%',
            textAlign: 'center',
            top: 0,
            width: '100%',
          }}
          >
            {alt}
          </p>
        </div>
      );
    }
    return (
      <Image
        id={id}
        data-testid={id}
        alt={alt}
        src={src}
        placeholder="blur"
        blurDataURL={`data:image/svg+xml;base64, ${toBase64(shimmer(width, height))}`}
        width={width}
        layout={layout}
        height={height}
        className={className}
        priority={priority}
        quality={parseInt(quality)}
        onError={() => handleError()}
      />
    );
  };

  return (
    <>
      {!isError && !isEmpty(src)
        ? renderImage()
        : (
          <div
            className="ps-image-placeholder"
            style={{
              height: height !== 100 && !ratioChange ? `${height}px` : '100%',
              width: '100%',
              position: absolute ? 'absolute' : 'relative',
            }}
          >
            <Image
              id={id}
              data-testid={id}
              alt={`${alt}-placeholder`}
              src={`${IMAGE_FOLDER_PATH}/placeholder/placeholder.png`}
              layout="fixed"
              width={width || 36}
              height={height || 36}
              priority
              quality={75}
              onError={() => handleError()}
            />
          </div>
        )}
    </>
  );
};

export default Images;
