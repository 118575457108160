import React, { CSSProperties, ReactNode } from 'react';
import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import dynamic from 'next/dynamic';

import { Alert, ALERT_TYPE } from '../../Alert';
import { GameItem } from '@/services/games';

const CommonModal = dynamic(() => import('@/components/Modal/CommonModal/CommonModal'), { ssr: false });

export type CommonAlertModalProps = {
  id?: string;
  open: boolean;
  closableBackDrop?: boolean;
  type?: ALERT_TYPE,
  image?: string;
  imageUrl?: string;
  imgWidth?: string | number;
  imgHeight?: string | number;
  gameCode?: string;
  header?: ReactNode | string;
  footer?: ReactNode | string;
  closeButton?: boolean;
  children?: ReactNode;
  onClose: () => void;
  onOk?: () => void;
  redirectToOnClose?: string;
  isShowIcon?: boolean;
  columnClass?: string;
  game?: GameItem;
  createdAt?: string;
  modalComponent?: 'antd' | 'ps';
  style?: CSSProperties;
  antdModalProps?: ModalProps;
};

const StyledAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
    padding: 0;
    background: #FCFCFC;
  }
  .ant-modal-body {
    padding: 0;
    height: fit-content;
    overflow: hidden;
  }
  .ant-modal-footer {
    padding: 16px;
    padding-top: 0;
  }
`;

const CommonAlertModal = ({
  id,
  open,
  closableBackDrop = true,
  type,
  image,
  imageUrl,
  imgWidth,
  imgHeight,
  gameCode,
  header,
  footer,
  closeButton,
  children,
  onClose,
  onOk,
  redirectToOnClose,
  isShowIcon = true,
  columnClass = '',
  game,
  createdAt,
  style,
  modalComponent = 'ps',
  antdModalProps,
}: CommonAlertModalProps): JSX.Element => {
  const router = useRouter();

  const onCloseHandle = () => {
    onClose();
    if (redirectToOnClose) {
      router.push(redirectToOnClose);
    }
  };

  const content = (
    <Alert
      id={id}
      type={type}
      image={image}
      imageUrl={imageUrl}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      gameCode={gameCode}
      header={header}
      footer={modalComponent === 'antd' ? null : footer}
      closeButton={closeButton}
      onClose={onCloseHandle}
      isShowIcon={isShowIcon}
      game={game}
    >
      {children}
    </Alert>
  );
  if (modalComponent === 'antd') {
    return (
      <StyledAntdModal
        centered
        destroyOnClose
        closable={false}
        title={null}
        visible={open}
        onCancel={onCloseHandle}
        onOk={onOk}
        {...antdModalProps}
      >
        {content}
      </StyledAntdModal>
    );
  }
  // TODO : dont use it
  return (
    <CommonModal
      style={style}
      open={open}
      closableBackDrop={closableBackDrop}
      onClose={onCloseHandle}
      columnClass={columnClass}
    >
      {content}
    </CommonModal>
  );
};

export const CommonAlertModalV2 = NiceModal.create((props: CommonAlertModalProps) => {
  const modal = useModal();
  return (
    <CommonAlertModal
      {...props}
      open={modal.visible}
      onClose={() => { modal.hide(); }}
    />
  );
});
export default CommonAlertModal;
