import React, { CSSProperties } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';
import { SHARED_ASSET_URL } from '@/models/buildtime-constant';

export enum CONTACT_LINK_TYPE {
  LINE = 'line',
  FACEBOOK = 'facebook',
  MESSENGER = 'messenger',
  EMAIL = 'email',
  TWITTER = 'twitter',
  WEBCHAT = 'webchat',
}

export type ContactLinkProps = {
  id?: string;
  href: string;
  title: string;
  subtitle: string;
  type: CONTACT_LINK_TYPE;
  className?: string;
  style?: CSSProperties;
  withBackgroundColor?: boolean;
};

const ContactLink = ({
  id = 'contact-link',
  href,
  title,
  subtitle,
  type,
  className,
  style,
  withBackgroundColor = true,
}: ContactLinkProps): JSX.Element => {
  const contactLinkClassName = classNames(className, 'ps-contact-link', {
    [`ps-contact-link--${type}`]: withBackgroundColor,
  });
  const contactLinkStyle = { ...style };

  return (
    <Link
      href={href}
      id={id}
      data-testid={id}
      className={contactLinkClassName}
      style={contactLinkStyle}
    >
      <>
        <div className="ps-contact-link__left-container">
          <img
            src={`${SHARED_ASSET_URL}/contacts/${type}.svg`}
            alt={`contact-${type}`}
            className="ps-contact-link__icon"
          />
          <span className="ps-contact-link__content">
            <strong className="ps-contact-link__title">{title}</strong>
            <small className="ps-contact-link__subtitle">{subtitle}</small>
          </span>
        </div>
        <div className="ps-contact-link__right-container">
          <RightOutlined />
        </div>
      </>
    </Link>
  );
};

export default ContactLink;
