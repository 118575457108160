import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { Button, BUTTON_TYPE } from '../Button';
import { Images } from '../Images';
import { GameIcon } from '../GameIcon';
import { gameAssetUrl } from '@/utils/utils';
import { GameItem } from '@/services/games';
import isInNewGameDuration from '@/utils/getNewGameDuration';

export enum ALERT_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
  NONE = '',
}

export type AlertProps = {
  id?: string;
  type?: ALERT_TYPE;
  image?: string;
  imageUrl?: string;
  imgWidth?: string | number;
  imgHeight?: string | number;
  gameCode?: string;
  header?: React.ReactNode | string;
  footer?: React.ReactNode | string;
  closeButton?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  onClose?: () => void;
  isShowIcon?: boolean;
  game?: GameItem;
};

const getIconClassName = (type: ALERT_TYPE): string => {
  switch (type) {
    case ALERT_TYPE.SUCCESS:
      return 'ps-icon-check-stroke';
    case ALERT_TYPE.ERROR:
      return 'ps-icon-info';
    case ALERT_TYPE.NONE:
    default:
      return '';
  }
};

const Alert = ({
  id,
  type = ALERT_TYPE.ERROR,
  image,
  imageUrl,
  imgWidth = '44px',
  imgHeight = '44px',
  gameCode,
  header,
  footer,
  closeButton,
  onClose,
  className,
  style,
  isShowIcon = true,
  game,
  children,
}: AlertProps): JSX.Element => {
  const alertClassName = classNames('ps-alert', className);
  const iconClassName = getIconClassName(type);
  const headerClassName = classNames({ 'ps-alert__header': header });
  const bodyModifier = classNames('ps-alert__body', {
    'ps-alert__body--extra-margin-with-icon': isShowIcon && (iconClassName !== ''),
  });

  const handleClick = () => {
    if (closeButton && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div id={id} data-testid={id} className={alertClassName} style={style}>
      { image && (
      <Images
        src={`/images/${image}`}
        width={44}
        height={44}
      />
      )}
      { imageUrl && (
      <img src={imageUrl} alt={imageUrl} width={44} />
      )}
      {gameCode && (
        <div style={{ width: imgWidth, height: imgHeight }}>
          <GameIcon
            src={gameAssetUrl(game, 'icon')}
            quality="100"
            alt={`${id}-image`}
            isCampaign={false}
            isNew={isInNewGameDuration(game?.created_at)}
            refType={game?.ref_type}
          />
        </div>
      )}
      <div className="ps-alert__content-wrapper">
        <div className={headerClassName}>
          { isShowIcon && <i className={iconClassName} /> }
          <strong data-testid={`${id}-header`}>{header}</strong>
          {closeButton && (
          <Button type={BUTTON_TYPE.PLAIN_TEXT} className="ms-auto p-0" onClick={handleClick}>
            <i className="ps-icon-close" />
            <span className="visually-hidden">Close</span>
          </Button>
          )}
        </div>
        <div data-testid={`${id}-body`} className={bodyModifier}>
          {children}
        </div>
        <div data-testid={`${id}-footer`} className="ps-alert__footer">
          {footer}
        </div>
      </div>
    </div>
  );
};

export default Alert;
