import React from 'react';
import { useTranslation } from 'next-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  FACEBOOK_OFFICIAL_URL,
  PRODUCT_EMAIL,
  TWITTER_OFFICIAL_URL,
} from '@/models/buildtime-constant';
import CommonAlertModal from '../CommonAlertModal/CommonAlertModal';
import { ContactLink, ContactButton, CONTACT_LINK_TYPE } from '../../Contact';
import { AlertContactModalProps } from './AlertContactModal.type';

const AlertContactModal = ({
  open,
  header,
  content,
  contactLinks = {
    facebook: FACEBOOK_OFFICIAL_URL,
    twitter: TWITTER_OFFICIAL_URL,
    email: PRODUCT_EMAIL,
  },
  closableBackDrop = true,
  onClose,
  isShowIcon = true,
  columnClass = '',
  isLock = false,
  redirectToOnClose,
}: AlertContactModalProps): JSX.Element => {
  const { t } = useTranslation(['modal', 'common']);
  const { twitter, email } = contactLinks;
  const footer = (
    <div data-testid="contact-link__section" className="d-flex flex-column justify-content-around mt-2">
      <ContactButton
        onClick={() => { window.location.assign(`${window.location.origin}/line`); }}
        id="contact-link__line"
        title={t('modal:alert.contact.links.line.title')}
        subtitle={t('modal:alert.contact.links.line.subtitle')}
        type={CONTACT_LINK_TYPE.LINE}
        style={{ marginBottom: 8 }}
      />
      <ContactLink
        id="contact-link__twitter"
        href={twitter}
        title={t('modal:alert.contact.links.twitter.title')}
        subtitle={t('modal:alert.contact.links.twitter.subtitle')}
        type={CONTACT_LINK_TYPE.TWITTER}
        style={{ marginBottom: 8 }}
      />
      <ContactLink
        id="contact-link__email"
        href={`mailto:${email}`}
        title={t('modal:alert.contact.links.email.title')}
        subtitle={t('modal:alert.contact.links.email.subtitle')}
        type={CONTACT_LINK_TYPE.EMAIL}
      />
    </div>
  );

  return (
    <CommonAlertModal
      id="alert-contact-modal"
      open={open}
      modalComponent="antd"
      antdModalProps={{
        okText: t('common:ok'),
        closeIcon: false,
        closable: false,
        maskClosable: false,
        onOk: onClose,
        cancelButtonProps: { hidden: true },
      }}
      header={header || (isLock ? t('modal:alert.contact.header-lock') : t('modal:alert.contact.header'))}
      footer={footer}
      closableBackDrop={closableBackDrop}
      onClose={onClose}
      isShowIcon={isShowIcon}
      columnClass={columnClass}
      redirectToOnClose={redirectToOnClose}
      closeButton
    >
      {content || t('modal:alert.contact.content')}
    </CommonAlertModal>
  );
};

export const AlertContactModalV2 = NiceModal.create((props: Partial<AlertContactModalProps>) => {
  const modal = useModal();
  if (!modal.visible) return null;
  return (
    <AlertContactModal
      {...props}
      open={modal.visible}
      onClose={() => {
        if (props.onClose) { props.onClose(); }
        modal.hide();
      }}
    />
  );
});

export default AlertContactModal;
