import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';
import { SHARED_ASSET_URL } from '@/models/buildtime-constant';
import Image from 'next/image';

export enum CONTACT_LINK_TYPE {
  LINE = 'line',
  FACEBOOK = 'facebook',
  MESSENGER = 'messenger',
  EMAIL = 'email',
  TWITTER = 'twitter',
  WEBCHAT = 'webchat',
}

export type ContactButtonProps = {
  id?: string;
  title: string;
  subtitle: string;
  type: CONTACT_LINK_TYPE;
  className?: string;
  style?: CSSProperties;
  withBackgroundColor?: boolean;
  onClick: () => void;
};

const ContactButton = ({
  id = 'contact-link',
  onClick,
  title,
  subtitle,
  type,
  className,
  style,
  withBackgroundColor = true,
}: ContactButtonProps): JSX.Element => {
  const contactLinkClassName = classNames(
    className,
    'ps-contact-link',
    { [`ps-contact-link--${type}`]: withBackgroundColor },
  );
  const contactLinkStyle = { ...style };

  return (
    <button onClick={onClick} id={id} data-testid={id} className={contactLinkClassName} style={contactLinkStyle}>
      <div className="ps-contact-link__left-container">
        <Image
          // DOTO: Should use only one type of image
          src={type === CONTACT_LINK_TYPE.MESSENGER ? `${SHARED_ASSET_URL}/contacts/${type}.webp` : `${SHARED_ASSET_URL}/contacts/${type}.svg`}
          alt={`contact-${type}`}
          width={36}
          height={36}
          className="ps-contact-link__icon"
        />
        <span className="ps-contact-link__content">
          <strong className="ps-contact-link__title" style={{ maxWidth: 'max-content' }}>
            {title}
          </strong>
          <small className="ps-contact-link__subtitle">
            {subtitle}
          </small>
        </span>
      </div>
      <div className="ps-contact-link__right-container">
        <RightOutlined />
      </div>
    </button>
  );
};

export default ContactButton;
